


































import {Component, Prop, Mixins} from 'vue-property-decorator';
import {ModelDataInterface} from '@/types/userDataInterface';
import {vxm} from '@/store';
import {PerformersSearchParamsInterface} from '@/types/PerformersSearchParamsInterface';
import Loader from '@/components/Loader.vue';
import NumbersMixin from '@/mixins/NumbersMixin';
import DefaultAvatarMixin from '@/mixins/DefaultAvatarMixin';

@Component({
  components: {Loader},
})
export default class Leaderboard extends Mixins(DefaultAvatarMixin, NumbersMixin) {
  @Prop({default: false}) dashboardView!: boolean;
  topModels: ModelDataInterface[] = [];
  isRequestSend = false;
  topModelsCount = 0;

  params: PerformersSearchParamsInterface = {
    page: 1,
    take: 9,
    sort: 'popular',
    status: 'active',
  };
  mounted() {
    this.getModels();
  }

  getMoreModels() {
    if (vxm.fan.leaderboard.length > 0 && !this.isRequestSend) {
      (this.params.page as number)++;
      this.getModels();
    }
  }

  getModels() {
    this.isRequestSend = true;

    vxm.fan
      .getLeaderboard(this.params)
      .then((res) => {
        this.topModelsCount = res.data.count;
        if (res.data.items.length > 0) {
          this.topModels.push(...res.data.items);
          (this.params.page as number)++;
        } else {
          this.isRequestSend = false;
        }
      })
      .catch((error) => {
        return error;
      })
      .then(() => {
        this.isRequestSend = false;
      });
    // if (!vxm.fan.leaderboard.length) {
    // } else {
    //   this.topModels = vxm.fan.leaderboard;
    // }
  }
}
